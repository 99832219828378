import React from 'react';

import { NextPageWithLayout } from 'next';
import NextImage from 'next/image';

import { BottomDownloadSection } from '../../components/BottomDownloadSection/BottomDownloadSection';
import { FAQ } from '../../components/FAQ/FAQ';
import { MetaTags } from '../../components/MetaTags/MetaTags';
import { ReviewSectionList } from '../../components/ReviewSection/ReviewSectionList';
import { CANONICAL_ROOT_URL, META_HOMEPAGE_TITLE, MILLION_USERS } from '../../constants/constants';

import depthLeft from './assets/depthLeft.png';
import depthRight from './assets/depthRight.png';
import { HomePageFeatureSection } from './components/HomePageFeatureSection/HomePageFeatureSection';
import { HomePageHeaderSection } from './components/HomePageHeaderSection/HomePageHeaderSection';
import { proSections } from './constants/constants';

const META_DESCRIPTION = `Join over ${MILLION_USERS} million anglers using Fishbrain. Fishbrain is the world's #1 fishing app for finding fishing spots, forecasts, socializing, logging catches & more. Fish smarter with Fishbrain.`;

export const Homepage: NextPageWithLayout = () => (
  <div className="grid grid-cols-1 grid-rows-1">
    <MetaTags
      title={META_HOMEPAGE_TITLE}
      link={CANONICAL_ROOT_URL}
      description={META_DESCRIPTION}
    />

    <NextImage
      className="w-col-3 col-start-1 row-start-1 hidden md:block"
      src={depthLeft}
      quality={100}
      alt="depth background left"
    />

    <NextImage
      className="w-col-4 col-start-1 row-start-1 hidden justify-self-end md:block"
      src={depthRight}
      quality={100}
      alt="depth background right"
    />

    <HomePageHeaderSection />

    <HomePageFeatureSection {...proSections[0]} />

    <HomePageFeatureSection {...proSections[1]} />

    <HomePageFeatureSection {...proSections[2]} />

    <HomePageFeatureSection {...proSections[3]} />

    <ReviewSectionList />

    <FAQ />

    <BottomDownloadSection />
  </div>
);
