import React from 'react';

import NextImage from 'next/image';

import { AppStoreButtons } from '../Clickables/Buttons/AppStoreButtons/AppStoreButtons';
import { Grid } from '../Grid/Grid';
import { Heading } from '../Heading/Heading';
import { QRCode } from '../QRCode/QRCode';
import { Text } from '../Text/Text';

import mobilePhonePreview from './assets/mobilePhonePreview.png';
import waveBlueBottom from './assets/waveBlueBottom.svg';
import styles from './BottomDownloadSection.module.css';

export const BottomDownloadSection = () => {
  return (
    <div
      className="mt-10 grid grid-cols-1 grid-rows-1 items-end sm:mt-0"
      data-testid="BottomDownloadSection"
    >
      {/* Background */}
      <div className="col-start-1 row-start-1 grid size-full grid-cols-1 grid-rows-1 items-end">
        <div className="col-start-1 row-start-1 flex size-full items-end">
          <div
            className={`mb-px size-full bg-cover bg-center bg-no-repeat ${styles.bottomDownloadSectionBackground}`}
            role="img"
            title="styleWaveBackground"
          />
        </div>
        <div className="col-start-1 row-start-1 flex size-full flex-col justify-end">
          <div
            style={{ backgroundImage: `url("${waveBlueBottom.src}")` }}
            className="h-6 w-full bg-cover bg-center bg-no-repeat sm:h-12 md:h-20 lg:h-28 xl:h-36"
            role="img"
            title="styleWaveBottom"
          />
        </div>
      </div>

      {/* Content */}
      <div className="col-start-1 row-start-1 -mt-20 grid grid-cols-1 grid-rows-1 xl:container sm:mt-2 sm:pl-11 md:pl-14 md:pt-0 lg:px-16 xl:mx-auto">
        <div className="col-start-1 row-start-1 mx-8 grid h-full grid-cols-1 sm:grid-cols-12">
          <div className="w-full justify-self-center pt-4 sm:col-start-6 sm:col-end-13 sm:pt-4 md:col-start-7 md:col-end-12 md:pt-20">
            <NextImage
              className="w-full object-cover"
              src={mobilePhonePreview}
              alt="Fishbrain app preview - depth maps"
              sizes="(min-width: 1680px) 620px,(min-width: 990px) 41vw, (min-width: 600px) 58vw, 100vw "
            />
          </div>
        </div>

        <Grid className="col-start-1 row-start-2 mx-8 flex flex-col sm:row-start-1 sm:mx-0 sm:items-start">
          <div className="col-start-1 col-end-5 self-center sm:col-end-7">
            <Heading variant="lg" className="text-center sm:text-left">
              Download Fishbrain
            </Heading>
            <Heading variant="lg" className="text-center sm:text-left">
              and fish smarter
            </Heading>
            <Text className="text-center sm:pr-20 sm:text-left md:pr-48">
              Your guide to finding the best fishing spots on any water.
            </Text>
            <div className="mb-10 flex items-center justify-center sm:justify-start">
              <QRCode
                className="rounded-md bg-sea-foam p-2"
                source="HomePageBottomDownloadSection"
              />
              <AppStoreButtons
                orientation="vertical"
                source="HomePageBottomDownloadSection"
                className="md:ml-4"
              />
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};
