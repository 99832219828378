import React, { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { toNumber } from '../../helpers/strings/toNumber';
import { Heading } from '../Heading/Heading';
import { ChevronDownIcon } from '../Icons/ChevronDownIcon';

export interface IAccordionData {
  id: string;
  title: string | React.JSX.Element;
  body: string | React.JSX.Element;
}

interface IAccordionProps {
  blocksData: IAccordionData[];
  className?: string;
  buttonClassName?: string;
  labelClassName?: string;
  blockClassName?: string;
  overrideTitleClassName?: string;
  openFirstItemInitially?: boolean;
  chevronAlignment?: 'left' | 'right';
}

export const Accordion = ({
  blocksData,
  className = '',
  buttonClassName = '',
  labelClassName = '',
  blockClassName = '',
  overrideTitleClassName = 'heading-text-xs text-left',
  openFirstItemInitially = false,
  chevronAlignment = 'right',
}: IAccordionProps) => {
  const [blocks, setBlocks] = useState<{ key: number; isOpen: boolean }[]>(
    Object.keys(Object.keys(blocksData)).map((value: string, index) => ({
      key: toNumber(value) || 0,
      isOpen: index === 0 && openFirstItemInitially,
    })),
  );
  const handleClickBlock = (index: number): void => {
    setBlocks(blocks.map(el => (el.key === index ? { ...el, isOpen: !el.isOpen } : el)));
  };
  return (
    <>
      {blocksData.map((blockData, index) => {
        const isBlockOpened = blocks.find(el => el.key === index)?.isOpen;
        const { title, body, id } = blockData;
        return (
          <div key={id} className={`tab w-full overflow-hidden rounded-md ${className}`}>
            <button
              type="button"
              className={twMerge(
                `flex w-full cursor-pointer items-center justify-center p-4 leading-normal`,
                buttonClassName,
              )}
              onClick={() => {
                handleClickBlock(index);
              }}
            >
              {typeof title === 'string' ? (
                <Heading variant="2xs" className={`mb-0 ${overrideTitleClassName}`}>
                  {title}
                </Heading>
              ) : (
                title
              )}
              <div
                className={`text-abyss transition-all duration-300 ${
                  isBlockOpened ? 'rotate-180' : ''
                } ${chevronAlignment === 'left' ? 'order-first mr-4' : 'ml-auto'}`}
              >
                <ChevronDownIcon />
              </div>
            </button>
            <div
              className={twMerge(
                `transition-max-h bg-gray-100 overflow-hidden leading-normal duration-500 ${
                  isBlockOpened ? 'max-h-screen' : 'max-h-0'
                }`,
                blockClassName,
              )}
            >
              <div className={twMerge(`p-5`, labelClassName)}>{body}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};
