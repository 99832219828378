export const SUBSCRIPTION_MANAGEMENT_PORTAL_STAGING =
  'https://billing.fishbrain.com/p/login/test_aEU5nv2VW8Sv8Ja9AA';

export const SUBSCRIPTION_MANAGEMENT_PORTAL_PRODUCTION =
  'https://billing.fishbrain.com/p/login/8wMaHZ5awczIeRyfYY';

export enum PlanDuration {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface IPaymentPlan {
  planID: ProPlanId;
  label: string;
  subText?: string;
  price: string;
  pricePerMonth?: string;
  // original price before discount
  originalPrice?: string;
  highlight?: boolean;
  disabled?: boolean;
  summaryText?: string;
  promoDealText?: string;
}

export type IPlanMetadata = IPaymentPlan & {
  disableDisplay?: boolean;
  isPrimaryOffer?: boolean;
  trialDays?: number;
  planDuration: PlanDuration;
};

export type ProPlanId =
  | 'monthly_12_99-7-day-trial'
  | 'yearly_79_99-7-day-trial'
  | 'monthly_9_99-no-trial'
  | 'monthly_12_99-no-trial'
  | 'yearly_74_99-no-trial'
  | 'yearly_79_99-no-trial'
  | 'monthly_9_99-90-day-trial'
  | 'monthly_12_99-90-day-trial'
  | 'yearly_79_99-90-day-trial';

export const PRIMARY_OFFER_ID: ProPlanId = 'yearly_79_99-7-day-trial';
export const SECONDARY_OFFER_ID: ProPlanId = 'monthly_12_99-7-day-trial';
export const FREE_TRIAL_DAYS = '7';

export const PARTNER_PRIMARY_OFFER_ID: ProPlanId = 'yearly_79_99-90-day-trial';

export const CURRENT_PLANS: IPlanMetadata[] = [
  {
    planID: 'monthly_12_99-7-day-trial',
    label: '1 month',
    subText: 'after free trial',
    price: '$12.99',
    pricePerMonth: '$12.99',
    planDuration: PlanDuration.MONTHLY,
    trialDays: 7,
    summaryText: '$12.99 monthly',
  },
  {
    planID: 'yearly_79_99-7-day-trial',
    label: '12 months',
    subText: 'after free trial',
    price: '$79.99',
    pricePerMonth: '$6.69',
    planDuration: PlanDuration.YEARLY,
    trialDays: 7,
    summaryText: '$79.99 annually',
    promoDealText: 'Save 37%',
  },
];

export const PARTNER_PLANS: IPlanMetadata[] = [
  {
    planID: 'monthly_12_99-90-day-trial',
    label: '1 Month',
    subText: 'after free trial',
    price: '$12.99',
    pricePerMonth: '$12.99',
    planDuration: PlanDuration.MONTHLY,
    trialDays: 90,
    summaryText: '$12.99 monthly',
  },
  {
    planID: 'yearly_79_99-90-day-trial',
    label: '12 Months',
    subText: 'after free trial',
    price: '$79.99',
    pricePerMonth: '$6.69',
    planDuration: PlanDuration.YEARLY,
    trialDays: 90,
    summaryText: '$79.99 yearly',
    promoDealText: 'Save 37%',
  },
];

const COUPON_PLANS: IPlanMetadata[] = [
  {
    planID: 'monthly_9_99-no-trial', // Legacy Plan, needs to be here for Coupon to still work
    label: '1 Month',
    price: '$9.99',
    disableDisplay: true,
    planDuration: PlanDuration.MONTHLY,
    promoDealText: 'Offer',
  },
  {
    planID: 'monthly_12_99-no-trial',
    label: '1 Month',
    price: '$12.99',
    disableDisplay: true,
    planDuration: PlanDuration.MONTHLY,
    promoDealText: 'Offer',
  },
  {
    planID: 'yearly_74_99-no-trial', // Legacy Plan, needs to be here for Coupon to still work
    label: '12 Months',
    price: '$74.99',
    disableDisplay: true,
    planDuration: PlanDuration.YEARLY,
    promoDealText: 'Offer',
  },
  {
    planID: 'yearly_79_99-no-trial',
    label: '12 Months',
    price: '$79.99',
    disableDisplay: true,
    planDuration: PlanDuration.YEARLY,
    promoDealText: 'Offer',
  },
];

export const PREMIUM_PLANS: IPlanMetadata[] = [...CURRENT_PLANS, ...COUPON_PLANS];
